@font-face {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    src: local('Open Sans'), local('OpenSans'), url("https://fonts.gstatic.com/s/opensans/v17/mem8YaGs126MiZpBA-UFVZ0bf8pkAg.woff2") format('woff2'), url(https://fonts.gstatic.com/s/opensans/v13/cJZKeOuBrn4kERxqtaUH3bO3LdcAZYWl9Si6vvxL-qU.woff) format('woff');
    font-display: swap;
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 600;
    src: local('Open Sans Semibold'), local('OpenSans-Semibold'), url("https://fonts.gstatic.com/s/opensans/v17/mem5YaGs126MiZpBA-UNirkOUuhpKKSTjw.woff2") format('woff2'), url(https://fonts.gstatic.com/s/opensans/v13/MTP_ySUJH_bn48VBG8sNSnhCUOGz7vYGh680lGh-uXM.woff) format('woff');
    font-display: swap;
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 700;
    src: local('Open Sans Bold'), local('OpenSans-Bold'), url("https://fonts.gstatic.com/s/opensans/v17/mem5YaGs126MiZpBA-UN7rgOUuhpKKSTjw.woff2") format('woff2'), url(https://fonts.gstatic.com/s/opensans/v13/k3k702ZOKiLJc3WVjuplzKRDOzjiPcYnFooOUGCOsRk.woff) format('woff');
    font-display: swap;
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
    font-family: 'Open Sans';
    font-style: italic;
    font-weight: 400;
    src: local('Open Sans Italic'), local('OpenSans-Italic'), url("https://fonts.gstatic.com/s/opensans/v17/mem6YaGs126MiZpBA-UFUK0Zdc1GAK6b.woff2") format('woff2'), url(https://fonts.gstatic.com/s/opensans/v13/xjAJXh38I15wypJXxuGMBrrIa-7acMAeDBVuclsi6Gc.woff) format('woff');
    font-display: swap;
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
    font-family: "Open Sans";
    font-style: italic;
    font-weight: 600;
    font-display: swap;
    src: local("Open Sans Semibold Italic"), local("OpenSans-SemiBoldItalic"),
        url("https://fonts.gstatic.com/s/opensans/v17/memnYaGs126MiZpBA-UFUKXGUdhrIqOxjaPX.woff2") format("woff2");
        unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
	font-family: "Open Sans";
	font-style: italic;
	font-weight: 700;
	font-display: swap;
	src: local("Open Sans Bold Italic"), local("OpenSans-BoldItalic"),
		 url("https://fonts.gstatic.com/s/opensans/v17/memnYaGs126MiZpBA-UFUKWiUNhrIqOxjaPX.woff2") format("woff2");
	unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

:root {
  /*
    * These below colours would be deprecated after design implementation
    * As these colours are used in current core, site and other repositories
  */

  /**
    * Below colours are from new style guidelines
  */
  /* Primary colors RED Orange */ /* Red */ /* Red */ /* Dark Red*/

  /* Black scale */ /* Black */ /* Thick Black */ /* light black */

  /* White scale */ /* white */ /* Light White */  /* One kind of white */

  /* Gray scale */ /* Gray */ /* Version of Light Gray */ /* Light Gray */ /* Davy Gray */ /* Solid Gray */ /* Solid Light Gray */ /* Dark Gray */ /* Dark Solid Gray */

  /* Inherited from Design Token to remove */

  /* Other Colors */ /* Turquoise */
  
  /* Extended */

  /* Messaging colors */ /* Anchor Blue */

  /* Message Alert colors */

  
  /* a.n.a Rebrand Colors */
  /* a.n.a Rebrand Colors Ends */

}

/* media queries for different width */

/* Medium plus screens */

/* Large plus screens */

/***
    Added only to Make the Header and Footer as Per the classice
    Have to remove once the Yoda Header Footer developed
***/

/***
    Adding  below for Header ReDesign
***/

/* This is to hide the app banner on overlay */

.bodyFixed #branch-banner-iframe {
    display: none;
}

body [class*="previewOverlay"] ~ header {
			top: 32px!important;
		}

body [class*="previewOverlay"] ~ header [data-automation-id="hamburgerBlockLink"] {
				top: 32px!important;
			}

.blockContentScroll {
    overflow: hidden;
}

@media (min-width: 0em) {

    #branch-banner-iframe.branch-animation {
        z-index: 7;
    }
}

#branch-banner-iframe.branch-animation { /* For Account Tooltip it is not getting hidden applying the z-index */
	}

.scrollBlock {
		position: relative;
  	}

.scrollBlock.mozLockScroll {
			position: relative;
		}

.scrollBlock [data-automation-id="sitePromoAccessibleView"] {
			background: #FFFFFF;
		}

.scrollBlock main[data-automation-id="mainContainerBlock"] [data-automation-id="headerBlock"] [data-automation-id="mainHeaderBlock"] [data-automation-id="headerMainBlock"] {
						background: #FFFFFF;
					}

.scrollBlock main[data-automation-id="mainContainerBlock"] [data-automation-id="headerBlock"] #jcp_syndicate .tooltip {
					align-items: center;
				}

.scrollBlock main[data-automation-id="mainContainerBlock"] [data-automation-id="headerBlock"] #jcp_syndicate .tooltip [data-automation-id="header_store_block"] {
					padding-top: 0;
				}

.scrollBlock main[data-automation-id="mainContainerBlock"] [data-automation-id="headerBlock"] #jcp_syndicate [data-automation-id="findStoreBlock"], .scrollBlock main[data-automation-id="mainContainerBlock"] [data-automation-id="headerBlock"] #jcp_syndicate [data-automation-id="bagLink"] {
					vertical-align: middle;
				}

.scrollBlock main[data-automation-id="mainContainerBlock"] [data-automation-id="headerBlock"] [data-automation-id="flyoutMenuBlock"] [data-automation-id="secondaryMenu"] {
						background: #FFFFFF;
					}

.scrollBlock main[data-automation-id="mainContainerBlock"] [data-automation-id="headerBlock"] [data-automation-id="flyoutMenuBlock"] [data-automation-id="secondaryMenu"]:before {
							content: '';
							background: rgba(32, 32, 32, 0.6);
							position: absolute;
							left: 0;
							top: 0;
							height: 100%;
							width: 100%;
							z-index: 9;
						}

@media (max-width: 599px), (min-width: 480px) and (max-width: 1023px) and (orientation: landscape) {
					.scrollBlock.searchScrollBlock .headerSearchNotDesktop [data-automation-id="headerMainBlock"] {
						height: 105px!important;
						transition: none!important;
					}

					.scrollBlock.searchScrollBlock .headerSearchNotDesktop [data-automation-id="searchBlock"] {
						flex: none!important;
						width: calc(100% - 48px)!important;
						left: 24px!important;
						top: 0!important;
						transform: translate3d(0, 48px, 0)!important;
						transition: transform 0.3s linear!important;
					}

					.scrollBlock.searchScrollBlock .headerSearchNotDesktop [data-automation-id="searchBlock"] {
						position: relative;
						will-change: transform;
					}
							.scrollBlock.searchScrollBlock .headerSearchNotDesktop [data-automation-id="searchBlock"] [data-automation-id="typeaheadInputContainer"] [data-automation-id="searchIconBlock"] {
								z-index: 1 !important;
							}

								.scrollBlock.searchScrollBlock .headerSearchNotDesktop [data-automation-id="searchBlock"] [data-automation-id="typeaheadInputContainer"] [data-automation-id="searchIconBlock"] svg {
									width: 24px!important;
									height: 24px!important;
									transform: translateX(0)!important;
									transition: transform 0.1s linear!important;
									position: relative!important;
									top: 0!important;
									right: 0px!important;
								}

							.scrollBlock.searchScrollBlock .headerSearchNotDesktop [data-automation-id="searchBlock"] [data-automation-id="typeaheadInputContainer"] [data-automation-id="search-text-box"] {
								opacity: 1!important;
							}
		}

.scrollBlock [data-automation-id="flyoutMenuBlock"] [data-automation-id="secondaryMenu"] {
				position: relative;
			}

@media (min-width: 0em) {

    [data-automation-id='search-text-box']::-webkit-input-placeholder {
        font-weight: 400 !important;
    }
}

[data-automation-id='search-text-box']:-ms-input-placeholder {
	}

@media (min-width: 0em) {

    [data-automation-id='search-text-box']::-moz-placeholder {
        font-weight: 400 !important;
    }

    [data-automation-id='search-text-box']::placeholder {
        font-weight: 400 !important;
    }
}

@media only screen and (max-width: 1279px) {
		[data-automation-id='mainContainerBlock'] {
			overflow: initial;
		}
	}

._7Wr2- {
	/* Only for Media Query Ends */
}

._7Wr2-.b3pcM .O-E6b {
    display: table-cell;
    border-width: 0px;
    vertical-align: middle;
}

@media (min-width: 80em) {

    ._7Wr2-.b3pcM .O-E6b {
        padding-left: 0.5rem;
        padding-right: 0.5rem;
    }
}

._7Wr2-.b3pcM .O-E6b.OZn9u {
    width: 172px;
}

._7Wr2-.b3pcM .O-E6b.wQkWy {
    padding-left: 1rem;
    padding-right: 1rem;
    padding-top: 0px;
    padding-bottom: 0px;
}

._7Wr2-.b3pcM .O-E6b.wQkWy .Vw4Mq {
    display: block;
}

._7Wr2-.b3pcM .O-E6b.wQkWy ._-7ex5 {
    --tw-text-opacity: 1;
    color: rgba(255, 255, 255, var(--tw-text-opacity));
}

._7Wr2-.b3pcM .O-E6b.uMeZL {
    padding-left: 1rem;
    padding-right: 1rem;
    padding-top: 0px;
    padding-bottom: 0px;
}

._7Wr2-.b3pcM .O-E6b.uMeZL ._02gLe {
    display: block;
    --tw-text-opacity: 1;
    color: rgba(255, 255, 255, var(--tw-text-opacity));
}

._7Wr2-.b3pcM .O-E6b.uMeZL .Zchd5 {
    font-size: 0.75rem;
    font-family: "Open Sans", OpenSans, Helvetica, arial, sans-serif;
    font-weight: 400;
    line-height: 1rem;
}

._7Wr2-.b3pcM .O-E6b.uMeZL .Or4qU {
    font-size: 1rem;
    line-height: 1.5rem;
    display: inline-block;
    vertical-align: middle;
    font-weight: 700;
}

._7Wr2-.b3pcM .O-E6b:last-child {
    border-right-width: 0px;
}

/* New Header */

._7Wr2-.b3pcM .O-E6b ._73Jfi, ._7Wr2-.b3pcM .O-E6b .x3dIv {
    fill: #202020;
    --tw-text-opacity: 1;
    color: rgba(32, 32, 32, var(--tw-text-opacity));
}

._7Wr2-.b3pcM .O-E6b .bn-xD {
    font-size: 0.75rem;
    line-height: 1rem;
    margin-top: -5px;
    text-align: center;
    font-weight: 400;
    --tw-text-opacity: 1;
    color: rgba(32, 32, 32, var(--tw-text-opacity));
}

._7Wr2-.b3pcM .O-E6b .BjOaG {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0px;
}

._7Wr2-.b3pcM .O-E6b .BjOaG .x3dIv {
    fill: #202020;
    --tw-text-opacity: 1;
    color: rgba(32, 32, 32, var(--tw-text-opacity));
}

._7Wr2-.b3pcM .DoDXN {
    pointer-events: none;
    transition-property: all;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 200ms;
    transition-timing-function: cubic-bezier(0.4, 0, 1, 1);
}

@media (min-width: 0em) {

    ._7Wr2-.b3pcM .DoDXN {
        z-index: 2;
    }
}

._7Wr2-.b3pcM .DoDXN {
			will-change: top, right, width, height;
		}

._7Wr2-.b3pcM.fYTBp {
    position: sticky;
    top: 0px;
    font-size: 0.875rem;
}

@media (min-width: 0em) {

    ._7Wr2-.b3pcM.fYTBp {
        z-index: 9;
    }
}

._7Wr2-.b3pcM.fYTBp.h8kpj {
				position: static;
			}

._7Wr2-.b3pcM.fYTBp.BHOdj {
    position: static !important;
}

._7Wr2-.b3pcM.fYTBp.NZVTk {
    visibility: hidden;
}

._7Wr2-.b3pcM.fYTBp.aoE4b .ZlqD- {
    position: static;
    margin-left: auto;
    margin-right: auto;
    height: 10rem;
    width: 100%;
}

@media (min-width: 0em) {

    ._7Wr2-.b3pcM.fYTBp.aoE4b .ZlqD- {
        top: initial;
        bottom: initial;
        left: initial;
    }
}

._7Wr2-.b3pcM.fYTBp._7Zj9H, ._7Wr2-.b3pcM.fYTBp.WQ5DD, ._7Wr2-.b3pcM.fYTBp._2FtUK {
    position: sticky;
				position: -webkit-sticky;
}

._7Wr2-.b3pcM.fYTBp._7Zj9H .-SF7v, ._7Wr2-.b3pcM.fYTBp.WQ5DD .-SF7v, ._7Wr2-.b3pcM.fYTBp._2FtUK .-SF7v {
    top: 0px;
    width: 100%;
    transition-duration: 300ms;
}

@media (min-width: 0em) {

    ._7Wr2-.b3pcM.fYTBp._7Zj9H .-SF7v, ._7Wr2-.b3pcM.fYTBp.WQ5DD .-SF7v, ._7Wr2-.b3pcM.fYTBp._2FtUK .-SF7v {
        z-index: 98;
    }
}

._7Wr2-.b3pcM.fYTBp._7Zj9H .-SF7v, ._7Wr2-.b3pcM.fYTBp.WQ5DD .-SF7v, ._7Wr2-.b3pcM.fYTBp._2FtUK .-SF7v {
					transition-timing-function: ease;
				}

/* New Header Changes */

._7Wr2-.b3pcM.fYTBp._2FtUK .ZlqD- {
    transition-duration: 300ms;
}

@media (min-width: 0em) {

    ._7Wr2-.b3pcM.fYTBp._2FtUK .ZlqD- {
        top: -151px;
    }
}

._7Wr2-.b3pcM.fYTBp._2FtUK .ZlqD- {
					transition-timing-function: ease;
				}

._7Wr2-.b3pcM.fYTBp._7Zj9H {
    --tw-shadow: 0 2px 4px 0 rgba(32, 32, 32, 0.25);
    --tw-shadow-colored: 0 2px 4px 0 var(--tw-shadow-color);
    box-shadow: 0 0 rgba(0,0,0,0), 0 0 rgba(0,0,0,0), var(--tw-shadow);
}

._7Wr2-.b3pcM.fYTBp._7Zj9H .ZlqD- {
    top: 0px;
}

@media (min-width: 0em) {

    ._7Wr2-.b3pcM.fYTBp._7Zj9H .ZlqD- {
        transition-duration: .6s;
    }
}

._7Wr2-.b3pcM.fYTBp._7Zj9H .ZlqD- {
					transition-timing-function: ease;
				}

._7Wr2-.b3pcM.fYTBp._7Zj9H .ZlqD- .-SF7v {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
}

/* for sticky filter */

@media (min-width: 80em) {

    ._7Wr2-.b3pcM.fYTBp._7Zj9H.ChuEs ~ article[id="contentContainer"] div[data-automation-id="filter-panel"] {
        padding-top: 110px;
    }

    ._7Wr2-.b3pcM.fYTBp._7Zj9H ~ article[id="contentContainer"] div[data-automation-id="filterStickyScoll"] {
        height: calc(100vh - 300px);
    }

    ._7Wr2-.b3pcM.fYTBp._0X6tN ~ article[id="contentContainer"] div[data-automation-id="filterStickyScoll"] {
        height: calc(100vh - 400px) !important;
    }

    ._7Wr2-.b3pcM.fYTBp.a5ATM ~ article[id="contentContainer"] #b9QG2 {
        margin-left: 0px !important;
        margin-right: 0px !important;
    }

    ._7Wr2-.b3pcM.fYTBp.ChuEs ~ article[id="contentContainer"] div[data-automation-id="filter-panel"] {
        padding-top: 10px;
    }

    ._7Wr2-.b3pcM.fYTBp._7Zj9H._5sYGX ~ article[id="contentContainer"] div[data-automation-id="filter-panel"] {
        padding-top: 0px;
    }
}

/* Only for Preview */

@media (min-width: 80em) {

    ._7Wr2-.b3pcM.fYTBp._7Zj9H.ZVJG5 ~ article[id="contentContainer"] div[data-automation-id="filter-panel"] {
        padding-top: 140px !important;
    }

    ._7Wr2-.b3pcM.fYTBp._7Zj9H.ZVJG5 ~ article[id="contentContainer"] div[data-automation-id="filterStickyScoll"] {
        height: calc(100vh - 300px) !important;
    }

    ._7Wr2-.b3pcM.fYTBp.ZVJG5 ~ article[id="contentContainer"] div[data-automation-id="filter-panel"] {
        padding-top: 40px !important;
    }

    ._7Wr2-.b3pcM.fYTBp.ZVJG5 ~ article[id="contentContainer"] div[data-automation-id="filterStickyScoll"] {
        height: calc(100vh - 250px);
    }

    ._7Wr2-.b3pcM.fYTBp._0X6tN.ZVJG5 ~ article[id="contentContainer"] div[data-automation-id="filter-panel"], ._7Wr2-.b3pcM.fYTBp._7Zj9H._5sYGX.ZVJG5 ~ article[id="contentContainer"] div[data-automation-id="filter-panel"] {
        padding-top: 0px !important;
    }
}

/* for sticky filter */

._7Wr2-.b3pcM.fYTBp .DoDXN {
    display: none !important;
}

/* Header Secondary */

._7Wr2-.b3pcM.fYTBp .-SF7v {
    position: relative;
    z-index: 10;
    --tw-bg-opacity: 1;
    background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
    padding-left: 0px;
    padding-right: 0px;
    transition-property: none;
}

@media (min-width: 0em) {

    ._7Wr2-.b3pcM.fYTBp .-SF7v {
        --tw-bg-opacity: 0.92;
        will-change: height;
    }
}

@media (min-width: 48em) and (max-width: 79.9375em) {

    ._7Wr2-.b3pcM.fYTBp .-SF7v {
        padding-bottom: 0.5rem;
    }
}

@media (max-width: 79.9375em) {

    ._7Wr2-.b3pcM.fYTBp .-SF7v {
        padding-top: 0.5rem;
    }
}

@media (min-width: 80em) {

    ._7Wr2-.b3pcM.fYTBp .-SF7v {
        padding-top: 0.75rem;
        padding-bottom: 0.5rem;
    }
}

@media (orientation: portrait) {

    @media (min-width: 48em) and (max-width: 79.9375em) {

        ._7Wr2-.b3pcM.fYTBp .-SF7v {
            padding-bottom: 9px;
        }
    }
}

._7Wr2-.b3pcM.fYTBp .-SF7v .B1vXF {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-content: space-between;
    align-items: center;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    text-align: center;
}

@media (max-width: 79.9375em) {

    ._7Wr2-.b3pcM.fYTBp .-SF7v .B1vXF {
        padding-bottom: 0.25rem;
    }
}

._7Wr2-.b3pcM.fYTBp .-SF7v .B1vXF {
						display: -webkit-flex;
						-webkit-flex-wrap: wrap;
					}

._7Wr2-.b3pcM.fYTBp .-SF7v .B1vXF .oLMJ5 {
    font-size: 0.75rem;
    --tw-text-opacity: 1;
    color: rgba(255, 255, 255, var(--tw-text-opacity));
}

@media (min-width: 80em) {

    ._7Wr2-.b3pcM.fYTBp .-SF7v .B1vXF .oLMJ5.VG1Es {
        padding-top: 0.25rem;
    }
}

/* Hamburger Menu */

._7Wr2-.b3pcM.fYTBp .-SF7v .B1vXF .oLMJ5._8zPmB {
    order: 1;
    display: flex;
    height: 2.5rem;
    align-self: flex-end;
								-webkit-order: 1;
								display: -ms-flexbox;
								-webkit-box-align: center;
								-ms-flex-align: center;
}

/* Logo Block */

._7Wr2-.b3pcM.fYTBp .-SF7v .B1vXF .oLMJ5.LkOQz {
    order: 2;
    margin-right: 2.5rem;
    text-align: left;
}

@media (max-width: 79.9375em) {

    ._7Wr2-.b3pcM.fYTBp .-SF7v .B1vXF .oLMJ5.LkOQz {
        margin-left: 0.5rem;
    }
}

@media (min-width: 80em) {

    ._7Wr2-.b3pcM.fYTBp .-SF7v .B1vXF .oLMJ5.LkOQz {
        height: 2.5rem;
    }
}

._7Wr2-.b3pcM.fYTBp .-SF7v .B1vXF .oLMJ5.LkOQz {
								-webkit-order: 2;
							}

._7Wr2-.b3pcM.fYTBp .-SF7v .B1vXF .oLMJ5.LkOQz .nZaxi {
    position: relative;
    top: 0px;
    display: block;
}

._7Wr2-.b3pcM.fYTBp .-SF7v .B1vXF .oLMJ5.LkOQz .WWfmV {
    display: inline-block;
    --tw-text-opacity: 1;
    color: rgba(231, 19, 36, var(--tw-text-opacity));
}

._7Wr2-.b3pcM.fYTBp .-SF7v .B1vXF .oLMJ5.LkOQz .Vl5q7 {
    position: relative;
}

@media (min-width: 0em) {

    ._7Wr2-.b3pcM.fYTBp .-SF7v .B1vXF .oLMJ5.LkOQz .Vl5q7 {
        z-index: 3;
    }
}

@media (max-width: 79.9375em) {

    ._7Wr2-.b3pcM.fYTBp .-SF7v .B1vXF .oLMJ5.LkOQz .Vl5q7 {
        width: calc(100% - 46px);
    }
}

@media (min-width: 80em) {

    ._7Wr2-.b3pcM.fYTBp .-SF7v .B1vXF .oLMJ5.LkOQz .Vl5q7 {
        width: 100%;
    }
}

._7Wr2-.b3pcM.fYTBp .-SF7v .B1vXF .oLMJ5.LkOQz .Vl5q7 {
										background: rgba(0, 0, 0, 0);
								}

._7Wr2-.b3pcM.fYTBp .-SF7v .B1vXF .oLMJ5.LkOQz .WXP0i {
    pointer-events: none;
}

._7Wr2-.b3pcM.fYTBp .-SF7v .B1vXF .oLMJ5 .YTqio {
    height: 100%;
    --tw-bg-opacity: 1;
    background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
}

._7Wr2-.b3pcM.fYTBp .-SF7v .B1vXF .oLMJ5 .YTqio.Kg50g .ISF8h {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    padding-top: 0px;
    padding-bottom: 0px;
}

._7Wr2-.b3pcM.fYTBp .-SF7v .B1vXF .oLMJ5 .YTqio.Kg50g .hXsnN {
    padding: 0.625rem;
    text-align: left;
}

._7Wr2-.b3pcM.fYTBp .-SF7v .B1vXF .oLMJ5 .YTqio.Kg50g .WXWl8 {
    padding: 0.625rem;
}

._7Wr2-.b3pcM.fYTBp .-SF7v .B1vXF .oLMJ5.XQDJa {
    position: relative;
    order: 3;
    padding: 0px;
}

@media (min-width: 0em) {

    ._7Wr2-.b3pcM.fYTBp .-SF7v .B1vXF .oLMJ5.XQDJa {
        width: 33%;
    }
}

._7Wr2-.b3pcM.fYTBp .-SF7v .B1vXF .oLMJ5.XQDJa {
								-webkit-order: 3;
								flex: 5 auto;
								-webkit-flex: 5 auto;
							}

._7Wr2-.b3pcM.fYTBp .-SF7v .B1vXF .oLMJ5.HM7-8 {
    order: 4;
    margin-left: 1.5rem;
    height: auto;
								-webkit-order: 4;
}

._7Wr2-.b3pcM.fYTBp .-SF7v .B1vXF .oLMJ5.HM7-8.OiU2T {
    margin-left: 0px;
}

/*account block start*/

._7Wr2-.b3pcM.fYTBp .-SF7v .B1vXF .oLMJ5 ._6h8ih {
    position: relative;
}

._7Wr2-.b3pcM.fYTBp .-SF7v .B1vXF .oLMJ5 ._6h8ih .G4218 .Iuzv1 {
    width: 15rem;
    border-width: 1px;
    border-style: solid;
    --tw-border-opacity: 1;
    border-color: rgba(191, 191, 191, var(--tw-border-opacity));
    --tw-bg-opacity: 1;
    background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
    --tw-shadow: 0 2px 4px 0 rgba(32, 32, 32, 0.25);
    --tw-shadow-colored: 0 2px 4px 0 var(--tw-shadow-color);
    box-shadow: 0 0 rgba(0,0,0,0), 0 0 rgba(0,0,0,0), var(--tw-shadow);
}

@media (min-width: 0em) {

    ._7Wr2-.b3pcM.fYTBp .-SF7v .B1vXF .oLMJ5 ._6h8ih .G4218 .Iuzv1 {
        z-index: 112;
    }
}

._7Wr2-.b3pcM.fYTBp .-SF7v .B1vXF .oLMJ5 ._6h8ih .G4218 .Iuzv1 .r3SG9 {
    margin: 1rem;
    width: 13rem;
    text-align: center;
    font-size: 1rem;
    font-weight: 700;
    line-height: 1.5;
    --tw-text-opacity: 1;
    color: rgba(255, 255, 255, var(--tw-text-opacity));
}

._7Wr2-.b3pcM.fYTBp .-SF7v .B1vXF .oLMJ5 ._6h8ih .G4218 .Iuzv1 .ctiqs {
    text-align: left;
    font-size: 0.875rem;
    --tw-text-opacity: 1;
    color: rgba(32, 32, 32, var(--tw-text-opacity));
}

@media (min-width: 0em) {

    ._7Wr2-.b3pcM.fYTBp .-SF7v .B1vXF .oLMJ5 ._6h8ih .G4218 .Iuzv1 .ctiqs {
        line-height: 1.71;
    }
}

._7Wr2-.b3pcM.fYTBp .-SF7v .B1vXF .oLMJ5 ._6h8ih .G4218 .Iuzv1 .ctiqs {
											margin-left: -5px;
										}

._7Wr2-.b3pcM.fYTBp .-SF7v .B1vXF .oLMJ5 ._6h8ih .G4218 .Iuzv1 .sap5G {
    display: inline-block;
    text-align: left;
    font-size: 0.875rem;
    font-weight: 700;
    line-height: 1.71;
    --tw-text-opacity: 1;
    color: rgba(40, 117, 188, var(--tw-text-opacity));
}

._7Wr2-.b3pcM.fYTBp .-SF7v .B1vXF .oLMJ5 ._6h8ih .G4218 .Iuzv1 .fNRea ._9nuJa {
    margin-top: 0.5rem;
}

._7Wr2-.b3pcM.fYTBp .-SF7v .B1vXF .oLMJ5 ._6h8ih .G4218 .Iuzv1 .fNRea ._9nuJa ._9F-a5 {
    height: 2.5rem;
    text-align: left;
}

._7Wr2-.b3pcM.fYTBp .-SF7v .B1vXF .oLMJ5 ._6h8ih .G4218 .Iuzv1 .fNRea ._9nuJa ._9F-a5 .b-0V5 {
    float: left;
    height: 100%;
    width: 100%;
    padding-left: 1rem;
    text-align: left;
    font-size: 0.875rem;
    font-weight: 700;
    line-height: 2.86;
    --tw-text-opacity: 1;
    color: rgba(32, 32, 32, var(--tw-text-opacity));
}

._7Wr2-.b3pcM.fYTBp .-SF7v .B1vXF .oLMJ5 ._6h8ih .G4218 .Iuzv1 .fNRea ._9nuJa ._9F-a5:hover {
													background-color: rgba(0, 108, 124, 0.1);
												}

/*parent li:last-child div.child-1.x {*/

._7Wr2-.b3pcM.fYTBp .-SF7v .B1vXF .oLMJ5 ._6h8ih .G4218 .Iuzv1 .fNRea ._9nuJa .-xd82:last-child:last-child {
    margin-left: 0px;
    margin-right: 0px;
    margin-top: 0.5rem;
    margin-bottom: 0px;
    height: 3.5rem;
    border-width: 1px;
    border-style: solid;
    --tw-border-opacity: 1;
    border-color: rgba(191, 191, 191, var(--tw-border-opacity));
    padding-left: 0px;
    padding-right: 0px;
    padding-top: 0.5rem;
    padding-bottom: 1rem;
}

._7Wr2-.b3pcM.fYTBp .-SF7v .B1vXF .oLMJ5 ._6h8ih .G4218 .Iuzv1 .fNRea ._9nuJa .-xd82:last-child .b-0V5 {
    --tw-text-opacity: 1;
    color: rgba(40, 117, 188, var(--tw-text-opacity));
}

._7Wr2-.b3pcM.fYTBp .-SF7v .B1vXF .oLMJ5 ._6h8ih .G4218 .Iuzv1 .fNRea ._9nuJa .qN9jT:last-child:last-child {
    margin-left: 0px;
    margin-right: 0px;
    margin-top: 0.5rem;
    margin-bottom: 0px;
    height: 3.5rem;
    border-width: 1px;
    border-style: solid;
    --tw-border-opacity: 1;
    border-color: rgba(191, 191, 191, var(--tw-border-opacity));
    padding-left: 0px;
    padding-right: 0px;
    padding-top: 0.5rem;
    padding-bottom: 1rem;
}

._7Wr2-.b3pcM.fYTBp .-SF7v .B1vXF .oLMJ5 ._6h8ih .G4218 .Iuzv1 .fNRea ._9nuJa .qN9jT:last-child .b-0V5 {
    --tw-text-opacity: 1;
    color: rgba(40, 117, 188, var(--tw-text-opacity));
}

._7Wr2-.b3pcM.fYTBp .-SF7v .B1vXF .oLMJ5 ._6h8ih .G4218 .Iuzv1 .fNRea ._9nuJa .qN9jT .b-0V5 ._7qXk4 {
    margin-top: 0px;
    margin-bottom: 0px;
    margin-right: 5px;
    margin-left: 0px;
    border-radius: 50%;
    border-width: 1px;
    border-style: solid;
    --tw-border-opacity: 1;
    border-color: rgba(231, 19, 36, var(--tw-border-opacity));
    padding-left: 1rem;
    padding-right: 1rem;
    padding-top: 14px;
    padding-bottom: 1rem;
}

._7Wr2-.b3pcM.fYTBp .-SF7v .B1vXF .oLMJ5 ._6h8ih .G4218 .Iuzv1 .fNRea ._9nuJa .qN9jT .b-0V5 ._7qXk4 ._6yHQQ {
    vertical-align: baseline;
}

@media (min-width: 0em) {

    ._7Wr2-.b3pcM.fYTBp .-SF7v .B1vXF .oLMJ5 ._6h8ih .G4218 .Iuzv1 .fNRea ._9nuJa .qN9jT .b-0V5 ._7qXk4 ._6yHQQ {
        padding-right: 3px;
    }

    ._7Wr2-.b3pcM.fYTBp .-SF7v .B1vXF .oLMJ5 ._6h8ih .G4218 .Iuzv1 .fNRea ._9nuJa .qN9jT .b-0V5 .b0BxQ {
        padding-left: 5px;
    }
}

._7Wr2-.b3pcM.fYTBp .-SF7v .B1vXF .oLMJ5 ._6h8ih .G4218 .Iuzv1 .Cms8U {
    width: 100%;
    cursor: default;
    border-width: 1px;
    border-style: solid;
    --tw-border-opacity: 1;
    border-color: rgba(191, 191, 191, var(--tw-border-opacity));
    text-align: center;
    font-size: 0.875rem;
    font-weight: 700;
    line-height: 1.5;
    --tw-text-opacity: 1;
    color: rgba(255, 255, 255, var(--tw-text-opacity));
    --tw-shadow: 0 2px 4px 0 rgba(32, 32, 32, 0.25);
    --tw-shadow-colored: 0 2px 4px 0 var(--tw-shadow-color);
    box-shadow: 0 0 rgba(0,0,0,0), 0 0 rgba(0,0,0,0), var(--tw-shadow);
}

@media (min-width: 0em) {

    ._7Wr2-.b3pcM.fYTBp .-SF7v .B1vXF .oLMJ5 ._6h8ih .G4218 .Iuzv1 .Cms8U {
        height: 72px;
    }
}

._7Wr2-.b3pcM.fYTBp .-SF7v .B1vXF .oLMJ5 ._6h8ih .G4218 .Iuzv1 .Cms8U ._7qXk4 {
    margin-top: 0px;
    border-radius: 50%;
    border-width: 1px;
    border-style: solid;
    --tw-border-opacity: 1;
    border-color: rgba(231, 19, 36, var(--tw-border-opacity));
    padding-left: 1rem;
    padding-right: 1rem;
    padding-bottom: 1rem;
}

@media (min-width: 0em) {

    ._7Wr2-.b3pcM.fYTBp .-SF7v .B1vXF .oLMJ5 ._6h8ih .G4218 .Iuzv1 .Cms8U ._7qXk4 {
        margin-right: 5px;
        padding-top: 14px;
    }
}

._7Wr2-.b3pcM.fYTBp .-SF7v .B1vXF .oLMJ5 ._6h8ih .G4218 .Iuzv1 .Cms8U ._7qXk4 ._6yHQQ {
    vertical-align: baseline;
}

@media (min-width: 0em) {

    ._7Wr2-.b3pcM.fYTBp .-SF7v .B1vXF .oLMJ5 ._6h8ih .G4218 .Iuzv1 .Cms8U ._7qXk4 ._6yHQQ {
        padding-right: 3px;
    }

    ._7Wr2-.b3pcM.fYTBp .-SF7v .B1vXF .oLMJ5 ._6h8ih .G4218 .Iuzv1 .Cms8U .b0BxQ {
        padding-left: 5px;
    }

    ._7Wr2-.b3pcM.fYTBp .-SF7v .B1vXF .oLMJ5 ._6h8ih .G4218 .V4bcz {
        height: 200px;
    }
}

._7Wr2-.b3pcM.fYTBp .-SF7v .B1vXF .oLMJ5 ._6h8ih ._2zDSc {
    position: relative;
    text-align: left;
}

._7Wr2-.b3pcM.fYTBp .-SF7v .B1vXF .oLMJ5 ._6h8ih ._2zDSc .GD-Bd .gAUl- {
    margin-right: 0px;
}

@media (min-width: 0em) {

    ._7Wr2-.b3pcM.fYTBp .-SF7v .B1vXF .oLMJ5 ._6h8ih ._2zDSc .GD-Bd .gAUl- {
        height: 30px;
        width: 30px;
    }
}

@media (min-width: 80em) {

    ._7Wr2-.b3pcM.fYTBp .-SF7v .B1vXF .oLMJ5 ._6h8ih ._2zDSc .GD-Bd .gAUl- {
        margin-right: 0.625rem;
    }
}

@media (min-width: 0em) {

    @media (min-width: 80em) {

        ._7Wr2-.b3pcM.fYTBp .-SF7v .B1vXF .oLMJ5 ._6h8ih ._2zDSc .GD-Bd .gAUl- {
            height: 34px;
            width: 34px;
        }
    }
}

._7Wr2-.b3pcM.fYTBp .-SF7v .B1vXF .oLMJ5 ._6h8ih ._2zDSc .sACu7 {
    position: absolute;
    left: 0px;
    width: 100%;
    text-align: center;
}

@media (min-width: 0em) {

    ._7Wr2-.b3pcM.fYTBp .-SF7v .B1vXF .oLMJ5 ._6h8ih ._2zDSc .sACu7 {
        bottom: -15px;
    }
}

@media (max-width: 79.9375em) {

    ._7Wr2-.b3pcM.fYTBp .-SF7v .B1vXF .oLMJ5 ._6h8ih .OiU2T {
        top: 3px;
    }
}

._7Wr2-.b3pcM.fYTBp .-SF7v .B1vXF .oLMJ5 ._6h8ih .OiU2T {
                                    cursor: pointer;
                                }

@media (max-width: 79.9375em) {

    ._7Wr2-.b3pcM.fYTBp .-SF7v .B1vXF .oLMJ5 ._6h8ih .edTdu {
        top: 0px;
    }
}

._7Wr2-.b3pcM.fYTBp .-SF7v .B1vXF .oLMJ5 ._6h8ih .x3dIv {
    --tw-text-opacity: 1;
    color: rgba(32, 32, 32, var(--tw-text-opacity));
}

/*account block close*/

._7Wr2-.b3pcM.fYTBp .-SF7v .B1vXF .oLMJ5 .G7zgP {
    order: 7;
    padding-top: 0px;
    padding-bottom: 0px;
    padding-left: 0.75rem;
    padding-right: 0px;
								-webkit-order: 7;
}

._7Wr2-.b3pcM.fYTBp .-SF7v .B1vXF .oLMJ5 .G7zgP.WbZdm {
    padding: 0px;
}

._7Wr2-.b3pcM.fYTBp .-SF7v .B1vXF .oLMJ5 .G7zgP.WbZdm .-bB6S .gAUl- {
    width: 2.5rem;
}

._7Wr2-.b3pcM.fYTBp .-SF7v .B1vXF .oLMJ5 .G7zgP .-bB6S {
    position: relative;
    display: inline-block;
    height: 2.5rem;
    --tw-text-opacity: 1;
    color: rgba(32, 32, 32, var(--tw-text-opacity));
}

@media (min-width: 0em) {

    ._7Wr2-.b3pcM.fYTBp .-SF7v .B1vXF .oLMJ5 .G7zgP .-bB6S {
        z-index: 3;
    }
}

._7Wr2-.b3pcM.fYTBp .-SF7v .B1vXF .oLMJ5 .G7zgP .-bB6S .Vx4dC {
    fill: #202020;
}

._7Wr2-.b3pcM.fYTBp .-SF7v .B1vXF .oLMJ5 .G7zgP .-bB6S ._4tN7O, ._7Wr2-.b3pcM.fYTBp .-SF7v .B1vXF .oLMJ5 .G7zgP .-bB6S span[data-automation-id='item-bag-count'] {
    font-size: 0.75rem;
    line-height: 1rem;
    font-family: "Open Sans", OpenSans, Helvetica, arial, sans-serif;
    position: absolute;
    background-color: transparent;
    padding-top: 0.125rem;
    padding-bottom: 0.125rem;
    padding-left: 0.375rem;
    padding-right: 0.375rem;
    text-align: center;
    font-weight: 600;
    --tw-text-opacity: 1;
    color: rgba(231, 19, 36, var(--tw-text-opacity));
}

@media (min-width: 0em) {

    ._7Wr2-.b3pcM.fYTBp .-SF7v .B1vXF .oLMJ5 .G7zgP .-bB6S ._4tN7O, ._7Wr2-.b3pcM.fYTBp .-SF7v .B1vXF .oLMJ5 .G7zgP .-bB6S span[data-automation-id='item-bag-count'] {
        right: 50%;
        top: 5px;
        min-width: 25px;
    }
}

._7Wr2-.b3pcM.fYTBp .-SF7v .B1vXF .oLMJ5 .G7zgP .-bB6S ._4tN7O,
									._7Wr2-.b3pcM.fYTBp .-SF7v .B1vXF .oLMJ5 .G7zgP .-bB6S span[data-automation-id='item-bag-count'] {
										transform: translateX(59%);
									}

._7Wr2-.b3pcM.fYTBp .-SF7v .B1vXF .oLMJ5 .G7zgP .-bB6S ._4tN7O.xVauB, ._7Wr2-.b3pcM.fYTBp .-SF7v .B1vXF .oLMJ5 .G7zgP .-bB6S span[data-automation-id='item-bag-count'].xVauB {
											font-size:10px;
											transform: translateX(51%);
										}

/* Header Secondary Ends */

._7Wr2-.b3pcM.fYTBp .Zge2I, ._7Wr2-.b3pcM.fYTBp ._9uCVz, ._7Wr2-.b3pcM.fYTBp .GD-Bd, ._7Wr2-.b3pcM.fYTBp .GQwSg {
    display: inline-block;
    text-align: left;
    vertical-align: middle;
}

._7Wr2-.b3pcM.fYTBp .Zge2I ._820pO, ._7Wr2-.b3pcM.fYTBp ._9uCVz ._820pO, ._7Wr2-.b3pcM.fYTBp .GD-Bd ._820pO, ._7Wr2-.b3pcM.fYTBp .GQwSg ._820pO {
    display: inline-block;
    vertical-align: middle;
    font-size: 1rem;
    font-weight: 700;
    line-height: 1.5;
}

/* Only for Preview */

._7Wr2-.b3pcM.fYTBp .FkOLo {
    position: absolute;
    left: 50%;
    bottom: 0.5rem;
    z-index: 9999;
    display: inline-block;
    border-width: 1px;
    border-style: solid;
    --tw-border-opacity: 1;
    border-color: rgba(32, 32, 32, var(--tw-border-opacity));
}

._7Wr2-.b3pcM.fYTBp .FkOLo .Q-i8b {
    position: absolute;
    margin-top: 25px;
    height: auto;
    border-radius: 0.375rem;
    --tw-bg-opacity: 1;
    background-color: rgba(32, 32, 32, var(--tw-bg-opacity));
    padding-top: 0.25rem;
    padding-bottom: 0.25rem;
    padding-left: 0.125rem;
    padding-right: 0.125rem;
    text-align: center;
    font-size: 0.75rem;
    font-weight: 700;
    --tw-text-opacity: 1;
    color: rgba(255, 255, 255, var(--tw-text-opacity));
}

@media (min-width: 0em) {

    ._7Wr2-.b3pcM.fYTBp .FkOLo .Q-i8b {
        width: 145px;
    }
}

._7Wr2-.b3pcM.fYTBp .FkOLo .Q-i8b {
					margin-left: -71px;
				}

._7Wr2-.b3pcM.fYTBp .FkOLo .Q-i8b.JP2c6 {
    padding-bottom: 0.75rem;
}

._7Wr2-.b3pcM.fYTBp .FkOLo .Q-i8b.JP2c6 .Zb22G {
    font-size: 1rem;
    line-height: 1.5rem;
    font-family: "Open Sans", OpenSans, Helvetica, arial, sans-serif;
    margin-bottom: 0.5rem;
    display: block;
    font-weight: 700;
}

._7Wr2-.b3pcM.fYTBp .FkOLo .Q-i8b.JP2c6 .U0SYQ {
    padding: 0.5rem !important;
}

._7Wr2-.b3pcM.fYTBp .FkOLo .Q-i8b.JP2c6 .U0SYQ {
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 0.75rem;
    display: block;
}

@media (min-width: 0em) {

    ._7Wr2-.b3pcM.fYTBp .FkOLo .Q-i8b.JP2c6 .U0SYQ {
        width: 110px;
        min-width: 10px;
    }
}

._7Wr2-.b3pcM.fYTBp .FkOLo .Q-i8b.JP2c6 .sap5G {
    font-size: 0.875rem;
    font-weight: 400;
    text-decoration-line: underline;
}

._7Wr2-.b3pcM.fYTBp .FkOLo .Q-i8b::after {
    position: absolute;
    left: 50%;
    bottom: 100%;
    border-style: solid;
}

@media (min-width: 0em) {

    ._7Wr2-.b3pcM.fYTBp .FkOLo .Q-i8b::after {
        margin-left: -0.625rem;
    }
}

._7Wr2-.b3pcM.fYTBp .FkOLo .Q-i8b::after {
					content: '';
					border-width: 10px;
					border-color: transparent transparent black transparent;
				}

._7Wr2-.b3pcM.fYTBp ._1eEMy, ._7Wr2-.b3pcM.fYTBp .Pfvey {
    font-size: 0.75rem;
    line-height: 1rem;
    font-family: "Open Sans", OpenSans, Helvetica, arial, sans-serif;
    font-weight: 400;
}

._7Wr2-.b3pcM.fYTBp .Rdjo4, ._7Wr2-.b3pcM.fYTBp ._820pO {
    font-size: 1rem;
    line-height: 1.5rem;
    position: relative;
    padding-right: 1.5rem;
    font-weight: 700;
}

._7Wr2-.b3pcM.fYTBp .Rdjo4 .upqqm, ._7Wr2-.b3pcM.fYTBp .Rdjo4 .V7e-9, ._7Wr2-.b3pcM.fYTBp ._820pO .upqqm, ._7Wr2-.b3pcM.fYTBp ._820pO .V7e-9 {
    position: absolute;
    top: 50%;
    right: 0.5rem;
    --tw-translate-y: 50%;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
    border-left-width: 4px;
    border-right-width: 4px;
    border-style: solid;
    --tw-border-opacity: 1;
    border-top-color: rgba(32, 32, 32, var(--tw-border-opacity));
    border-right-color: transparent;
    border-left-color: transparent;
}

@media (min-width: 0em) {

    ._7Wr2-.b3pcM.fYTBp .Rdjo4 .upqqm, ._7Wr2-.b3pcM.fYTBp .Rdjo4 .V7e-9, ._7Wr2-.b3pcM.fYTBp ._820pO .upqqm, ._7Wr2-.b3pcM.fYTBp ._820pO .V7e-9 {
        border-top-width: 5px;
        --tw-content: '';
        content: var(--tw-content);
    }
}

._7Wr2-.b3pcM.fYTBp .Rdjo4 .V7e-9, ._7Wr2-.b3pcM.fYTBp ._820pO .V7e-9 {
    --tw-border-opacity: 1;
    border-top-color: rgba(255, 255, 255, var(--tw-border-opacity));
}

._7Wr2-.b3pcM.fYTBp .AXvy2 {
    position: relative;
}

@media (min-width: 0em) {

    ._7Wr2-.b3pcM.fYTBp .AXvy2 .bN5K9, ._7Wr2-.b3pcM.fYTBp .AXvy2 .Zl-EN {
        right: -27px;
    }
}

._7Wr2-.b3pcM.fYTBp ._8kfjd .Hzlcy {
    height: 100%;
    max-height: 0px;
    width: 100%;
    border-radius: 0px;
    padding: 0px;
}

._7Wr2-.b3pcM.fYTBp ._8kfjd .Hzlcy .nTU1C {
    height: 100%;
    max-height: 0px;
}

._7Wr2-.b3pcM.fYTBp ._8kfjd .Hzlcy .kRRAm {
    margin-top: 0px;
    display: flex;
    height: 100%;
    max-height: 0px;
    flex-direction: column;
}

._7Wr2-.b3pcM.fYTBp .VVPyN {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
    padding-left: 1.25rem;
    padding-right: 1.25rem;
    padding-bottom: 1.25rem;
    text-align: left;
}

@media (min-width: 0em) {

    ._7Wr2-.b3pcM.fYTBp .VVPyN {
        max-width: 200px;
        padding-top: 30px;
    }
}

._7Wr2-.b3pcM.fYTBp .VVPyN .bzVFW {
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    --tw-text-opacity: 1;
    color: rgba(40, 117, 188, var(--tw-text-opacity));
}

._7Wr2-.b3pcM.fYTBp .VVPyN .TUB4O {
    display: block;
    font-size: 0.875rem;
    font-weight: 700;
}

._7Wr2-.b3pcM.fYTBp .VVPyN .u7Ro- {
    margin-top: 0.625rem;
    display: inline-block;
}

._7Wr2-.b3pcM.fYTBp .VVPyN ._4M8Jh {
    --tw-text-opacity: 1;
    color: rgba(40, 117, 188, var(--tw-text-opacity));
}

._7Wr2-.b3pcM.fYTBp .VVPyN .jVNuL {
    margin-top: 0.625rem;
}

._7Wr2-.b3pcM.fYTBp .VVPyN .RTKqH {
    margin-top: 0.625rem;
}

._7Wr2-.b3pcM.fYTBp .VVPyN .IlJJV {
    margin-left: 0px;
    margin-right: 0px;
    margin-bottom: 0.625rem;
    font-size: 0.875rem;
    font-weight: 700;
}

@media (min-width: 0em) {

    ._7Wr2-.b3pcM.fYTBp .VVPyN .IlJJV {
        margin-top: 15px;
    }
}

._7Wr2-.b3pcM.fYTBp .VVPyN .ZHTcQ {
					font-size: 10px;
				}

._7Wr2-.b3pcM.fYTBp .VVPyN .li97j {
    padding: 0px;
    text-align: left;
					font-size: 10px;
}

._7Wr2-.b3pcM.fYTBp .VVPyN .GNcRT {
					-moz-column-count: 2;
					     column-count: 2;
				}

._7Wr2-.b3pcM.fYTBp .VVPyN .Q2v2N {
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 0px;
    display: block;
    text-align: center;
}

@media (min-width: 0em) {

    ._7Wr2-.b3pcM.fYTBp .VVPyN .Q2v2N {
        margin-top: 25px;
    }
}

._7Wr2-.b3pcM.fYTBp .V6rBq {
    position: absolute;
    right: 0.625rem;
    --tw-rotate: 45deg;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
    --tw-text-opacity: 1;
    color: rgba(32, 32, 32, var(--tw-text-opacity));
}

@media (min-width: 0em) {

    ._7Wr2-.b3pcM.fYTBp .V6rBq {
        --tw-content: '';
        content: var(--tw-content);
    }
}

._7Wr2-.b3pcM.fYTBp .Qvkzu {
    clear: both;
    width: 100%;
    --tw-bg-opacity: 1;
    background-color: rgba(102, 102, 102, var(--tw-bg-opacity));
    padding-top: 0.5rem;
    padding-right: 1rem;
    padding-bottom: 0.5rem;
    padding-left: 1rem;
    font-size: 1rem;
    font-weight: 700;
    --tw-shadow: 0 2px 4px 0 rgba(32, 32, 32, 0.25);
    --tw-shadow-colored: 0 2px 4px 0 var(--tw-shadow-color);
    box-shadow: 0 0 rgba(0,0,0,0), 0 0 rgba(0,0,0,0), var(--tw-shadow);
}

@media (min-width: 0em) {

    ._7Wr2-.b3pcM.fYTBp .Qvkzu {
        margin-bottom: 15px;
    }
}

._7Wr2-.b3pcM.fYTBp .Qvkzu ._8-16c {
    font-size: 1rem;
    font-weight: 400;
    --tw-text-opacity: 1;
    color: rgba(32, 32, 32, var(--tw-text-opacity));
}

@media (min-width: 0em) {

    ._7Wr2-.b3pcM.fYTBp .Qvkzu ._8-16c {
        padding-top: 5px;
    }
}

._7Wr2-.b3pcM.fYTBp .Qvkzu ._1eEMy {
    float: left;
    font-size: 1rem;
    font-weight: 400;
    --tw-text-opacity: 1;
    color: rgba(32, 32, 32, var(--tw-text-opacity));
}

._7Wr2-.b3pcM.fYTBp.I279Z .ZlqD- .-SF7v {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
}

._7Wr2-.b3pcM.fYTBp.I279Z .ZlqD- ._8-Fu9 [data-automation-id='secondaryMenu'] {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
}

/* Only for Media Query */

@media (max-width: 47.999em) {
				._7Wr2-.o24CB .O-E6b.OZn9u, ._7Wr2-.o24CB .O-E6b.BjOaG {
        display: none;
    }
			}

/* New Header Ends */

@media (max-width: 47.999em) {

    ._7Wr2-.o24CB .O-E6b.lJ9Qw {
        padding-left: 0.25rem;
        padding-right: 0.25rem;
    }
}

@media (max-width: 79.9375em) {

    ._7Wr2-.o24CB .O-E6b.lJ9Qw {
        border-right-width: 0px;
        padding-top: 0px;
        padding-bottom: 0px;
        padding-left: 0.75rem;
        padding-right: 0.75rem;
    }
}

@media (max-width: 47.999em) {

._7Wr2-.o24CB .O-E6b.wQkWy {
        padding-top: 0px;
        padding-bottom: 0px;
        padding-right: 1rem;
        padding-left: 0.5rem;
        display: none;
    }}

@media (max-width: 79.9375em) {

._7Wr2-.o24CB .O-E6b.wQkWy.lJ9Qw {
        border-right-width: 0px;
        padding-left: 0.75rem;
        padding-right: 0.75rem;
        padding-top: 0px;
        padding-bottom: 0px;
    }
					}

@media (max-width: 47.999em) {

._7Wr2-.o24CB .O-E6b.wQkWy.lJ9Qw {
        padding-left: 0.25rem;
        padding-right: 0.25rem;
        padding-top: 0px;
        padding-bottom: 0px;
    }
					}

@media (max-width: 79.9375em) {

._7Wr2-.o24CB .O-E6b._6h8ih.lJ9Qw {
        border-right-width: 0px;
        padding-top: 0px;
        padding-bottom: 0px;
        padding-right: 0.5rem;
        padding-left: 0.625rem;
    }
					}

@media (max-width: 47.999em) {

._7Wr2-.o24CB .O-E6b._6h8ih.lJ9Qw {
        padding-top: 0px;
        padding-bottom: 0px;
        padding-left: 0.25rem;
        padding-right: 0.5rem;
    }

._7Wr2-.o24CB .O-E6b.uMeZL {
        padding-top: 0px;
        padding-bottom: 0px;
        padding-left: 0.5rem;
        padding-right: 1rem;
        display: none;
    }
					}

@media (max-width: 79.9375em) {
				._7Wr2-.o24CB.fYTBp .S4BtC._8-Fu9 {
        display: none;
    }
					._7Wr2-.o24CB.fYTBp.aoE4b .DoDXN, ._7Wr2-.o24CB.fYTBp._0X6tN .DoDXN, ._7Wr2-.o24CB.fYTBp._7Zj9H .DoDXN {
        position: absolute;
        right: 1rem;
    }
					@media (max-width: 79.9375em) {

        ._7Wr2-.o24CB.fYTBp.aoE4b .DoDXN, ._7Wr2-.o24CB.fYTBp._0X6tN .DoDXN, ._7Wr2-.o24CB.fYTBp._7Zj9H .DoDXN {
            top: 49px;
        }
    }

					@media (min-width: 0em) {

        ._7Wr2-.o24CB.fYTBp.aoE4b .-SF7v, ._7Wr2-.o24CB.fYTBp._0X6tN .-SF7v, ._7Wr2-.o24CB.fYTBp._7Zj9H .-SF7v {
            height: 97px;
        }
    }

					@media (min-width: 48em) {

        ._7Wr2-.o24CB.fYTBp.aoE4b .-SF7v, ._7Wr2-.o24CB.fYTBp._0X6tN .-SF7v, ._7Wr2-.o24CB.fYTBp._7Zj9H .-SF7v {
            height: 104px;
        }
    }
								._7Wr2-.o24CB.fYTBp.aoE4b .ZlqD- .B1vXF .oLMJ5.XQDJa, ._7Wr2-.o24CB.fYTBp._0X6tN .ZlqD- .B1vXF .oLMJ5.XQDJa, ._7Wr2-.o24CB.fYTBp._7Zj9H .ZlqD- .B1vXF .oLMJ5.XQDJa {
        position: absolute;
        left: 1.5rem;
        top: 0px;
        margin: 0px;
        margin-bottom: 0.25rem;
        margin-top: 0.5rem;
        flex: none;
        padding: 0px;
    }
								@media (max-width: 79.9375em) {

        ._7Wr2-.o24CB.fYTBp.aoE4b .ZlqD- .B1vXF .oLMJ5.XQDJa, ._7Wr2-.o24CB.fYTBp._0X6tN .ZlqD- .B1vXF .oLMJ5.XQDJa, ._7Wr2-.o24CB.fYTBp._7Zj9H .ZlqD- .B1vXF .oLMJ5.XQDJa {
            margin-top: 0.25rem;
            margin-bottom: 0px;
            width: calc(100% - 48px);
            will-change: transform,opacity;
        }
    }
								._7Wr2-.o24CB.fYTBp.aoE4b .ZlqD- .B1vXF .oLMJ5.XQDJa, ._7Wr2-.o24CB.fYTBp._0X6tN .ZlqD- .B1vXF .oLMJ5.XQDJa, ._7Wr2-.o24CB.fYTBp._7Zj9H .ZlqD- .B1vXF .oLMJ5.XQDJa {
									transform: translate3d(0, 48px, 0);
									transition: transform 0.3s linear, opacity 0.3s linear;
                                }

									._7Wr2-.o24CB.fYTBp.aoE4b .ZlqD- .B1vXF .oLMJ5.XQDJa [data-automation-id='typeaheadInputContainer'], ._7Wr2-.o24CB.fYTBp._0X6tN .ZlqD- .B1vXF .oLMJ5.XQDJa [data-automation-id='typeaheadInputContainer'], ._7Wr2-.o24CB.fYTBp._7Zj9H .ZlqD- .B1vXF .oLMJ5.XQDJa [data-automation-id='typeaheadInputContainer'] {
        --tw-shadow: 0 0 0 1px rgb(191, 191, 191);
        --tw-shadow-colored: 0 0 0 1px var(--tw-shadow-color);
        box-shadow: 0 0 rgba(0,0,0,0), 0 0 rgba(0,0,0,0), var(--tw-shadow);
    }
									._7Wr2-.o24CB.fYTBp.aoE4b .ZlqD- .B1vXF .oLMJ5.XQDJa [data-automation-id='search-text-box'], ._7Wr2-.o24CB.fYTBp._0X6tN .ZlqD- .B1vXF .oLMJ5.XQDJa [data-automation-id='search-text-box'], ._7Wr2-.o24CB.fYTBp._7Zj9H .ZlqD- .B1vXF .oLMJ5.XQDJa [data-automation-id='search-text-box'] {
        opacity: 1;
    }
					._7Wr2-.o24CB.fYTBp.WQ5DD .DoDXN {
        pointer-events: none;
        position: absolute;
        top: 0px;
        right: 3rem;
    }

					._7Wr2-.o24CB.fYTBp.WQ5DD .-SF7v {
        margin-bottom: 0px;
        height: 3.5rem;
        background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
        --tw-bg-opacity: .92;
        padding-top: 0.5rem;
        padding-bottom: 0.5rem;
        --tw-shadow: 0 2px 4px 0 rgba(32, 32, 32, 0.25);
        --tw-shadow-colored: 0 2px 4px 0 var(--tw-shadow-color);
        box-shadow: 0 0 rgba(0,0,0,0), 0 0 rgba(0,0,0,0), var(--tw-shadow);
    }

						._7Wr2-.o24CB.fYTBp.WQ5DD .-SF7v svg {
        fill: #202020;
    }
									._7Wr2-.o24CB.fYTBp.WQ5DD .-SF7v .B1vXF .oLMJ5.LkOQz .WWfmV {
        --tw-text-opacity: 1;
        color: rgba(231, 19, 36, var(--tw-text-opacity));
    }

										._7Wr2-.o24CB.fYTBp.WQ5DD .-SF7v .B1vXF .oLMJ5.LkOQz .WWfmV svg {
        fill: #e71324;
    }
										._7Wr2-.o24CB.fYTBp.WQ5DD .-SF7v .B1vXF .oLMJ5 .G7zgP .-bB6S .Vx4dC {
        fill: #202020;
    }

										._7Wr2-.o24CB.fYTBp.WQ5DD .-SF7v .B1vXF .oLMJ5 .G7zgP .-bB6S ._4tN7O {
        --tw-text-opacity: 1;
        color: rgba(231, 19, 36, var(--tw-text-opacity));
    }

								._7Wr2-.o24CB.fYTBp.WQ5DD .-SF7v .B1vXF .oLMJ5.XQDJa {
        position: absolute;
        left: 1.5rem;
        top: 0px;
        z-index: 2;
        margin-top: 0px;
        width: calc(100% - 48px);
        will-change: transform,opacity;
									transition: transform 0.3s linear, opacity 0.3s linear;
    }
									._7Wr2-.o24CB.fYTBp.WQ5DD .ZlqD- .B1vXF .oLMJ5.XQDJa [data-automation-id='typeaheadInputContainer'] {
        border-style: none;
        background-color: transparent;
        --tw-shadow: 0 0 rgba(0,0,0,0);
        --tw-shadow-colored: 0 0 rgba(0,0,0,0);
        box-shadow: 0 0 rgba(0,0,0,0), 0 0 rgba(0,0,0,0), var(--tw-shadow);
        outline: 2px solid transparent;
        outline-offset: 2px;
    }

										._7Wr2-.o24CB.fYTBp.WQ5DD .ZlqD- .B1vXF .oLMJ5.XQDJa [data-automation-id='typeaheadInputContainer'] [data-automation-id='searchIconBlock'] {
        z-index: 0;
    }

                                            ._7Wr2-.o24CB.fYTBp.WQ5DD .ZlqD- .B1vXF .oLMJ5.XQDJa [data-automation-id='typeaheadInputContainer'] [data-automation-id='searchIconBlock'] svg {
        height: 2.25rem;
        width: 2.25rem;
                                                transition: transform 0.3s linear, width 0.3s linear,
                                                height 0.3s linear;
    }

									._7Wr2-.o24CB.fYTBp.WQ5DD .ZlqD- .B1vXF .oLMJ5.XQDJa [data-automation-id='search-text-box'] {
        opacity: 0;
    }
			}

@media (min-width: 48em) and (max-width: 79.9375em){
					._7Wr2-.o24CB.fYTBp.WQ5DD .-SF7v, ._7Wr2-.o24CB.fYTBp._7Zj9H .-SF7v {
        position: relative;
    }
					._7Wr2-.o24CB.fYTBp.REbuj .DoDXN {
        position: static;
        display: none;
        opacity: 0;
        transition-property: none;
    }
						._7Wr2-.o24CB.fYTBp.REbuj .ZlqD- .-SF7v {
        transition-property: none;
    }
						@media (max-width: 47.999em) {

        ._7Wr2-.o24CB.fYTBp.REbuj .ZlqD- .-SF7v {
            height: 92px;
        }
    }
						@media (min-width: 48em) and (max-width: 79.9375em) {

        ._7Wr2-.o24CB.fYTBp.REbuj .ZlqD- .-SF7v {
            margin-bottom: 0px;
            height: 100px;
        }
    }
			}

@media (min-width: 0em) {

    ._7Wr2-.o24CB.fYTBp._7Zj9H .ZlqD- {
        height: 56px;
    }
}

@media (max-width: 79.9375em) {

@media (max-width: 79.9375em) {

        ._7Wr2-.o24CB.fYTBp._7Zj9H .ZlqD- {
            height: 97px;
        }
    }
					}

._7Wr2-.o24CB.fYTBp .S4BtC._8-Fu9 {
    display: none !important;
}

/* Header Secondary */

@media (max-width: 47.999em) {

    ._7Wr2-.o24CB.fYTBp .-SF7v .B1vXF .oLMJ5.VG1Es {
        margin-top: -0.25rem;
        padding-top: 0px;
    }
}

/* Logo Block */

@media (max-width: 79.9375em) {

._7Wr2-.o24CB.fYTBp .-SF7v .B1vXF .oLMJ5.LkOQz {
        flex-grow: 1;
        padding-top: 0.25rem;
        padding-bottom: 0.25rem;
								-webkit-flex-grow: 1;
								line-height: 0;
    }
							}

@media (max-width: 47.999em) {

._7Wr2-.o24CB.fYTBp .-SF7v .B1vXF .oLMJ5.XQDJa {
        order: 8;
        margin-top: 0.5rem;
								-webkit-order: 8;
								flex: 1 100%;
								-webkit-flex: 1 100%;
    }
							}

@media (min-width: 48em) and (max-width: 79.9375em) {

._7Wr2-.o24CB.fYTBp .-SF7v .B1vXF .oLMJ5.XQDJa {
        order: 8;
        margin-top: 0.5rem;
								-webkit-order: 8;
								flex: 1 100%;
								-webkit-flex: 1 100%;
    }
							}

@media (max-width: 47.999em) {

._7Wr2-.o24CB.fYTBp .-SF7v .B1vXF .oLMJ5.HM7-8 {
        order: 2;
								-webkit-order: 2;
    }
							}

@media (min-width: 48em) and (max-width: 79.9375em) {

._7Wr2-.o24CB.fYTBp .-SF7v .B1vXF .oLMJ5.HM7-8 {
        order: 2;
								-webkit-order: 2;
    }
							}

/*account block start*/

@media (max-width: 79.9375em) {

._7Wr2-.o24CB.fYTBp .-SF7v .B1vXF .oLMJ5 ._6h8ih .G4218 .Iuzv1 {
										top: 126%;
										right: -21px;
								}
									}

._7Wr2-.o24CB.fYTBp .-SF7v .B1vXF .oLMJ5 ._6h8ih .I6WU8, ._7Wr2-.o24CB.fYTBp .-SF7v .B1vXF .oLMJ5 ._6h8ih .rDuyr {
    display: none;
}

@media (max-width: 47.999em){

._7Wr2-.o24CB.fYTBp .-SF7v .B1vXF .oLMJ5 ._6h8ih .I6WU8 {
        display: block;
    }
								}

@media (min-width: 48em) and (max-width: 79.9375em) {

._7Wr2-.o24CB.fYTBp .-SF7v .B1vXF .oLMJ5 ._6h8ih ._2zDSc {
        display: block;
    }
								}

@media (max-width: 47.999em) {
										._7Wr2-.o24CB.fYTBp .-SF7v .B1vXF .oLMJ5 ._6h8ih.lJ9Qw .GD-Bd .gAUl- {
        height: 1.5rem;
        width: 1.5rem;
    }
										@media (min-width: 0em) {

        ._7Wr2-.o24CB.fYTBp .-SF7v .B1vXF .oLMJ5 ._6h8ih.lJ9Qw .GD-Bd .gAUl- {
            margin-top: 3px;
        }
    }
								}

/*account block close*/

@media (max-width: 79.9375em) {

._7Wr2-.o24CB.fYTBp .-SF7v .B1vXF .oLMJ5 .G7zgP {
        position: relative;
        padding-left: 0px;
    }

@media (min-width: 0em) {

        ._7Wr2-.o24CB.fYTBp .-SF7v .B1vXF .oLMJ5 .G7zgP {
            z-index: 2;
        }
    }
							}

/* Header Secondary Ends */

@media (max-width: 47.999em){

._7Wr2-.o24CB.fYTBp .FkOLo .Q-i8b {
        margin-left: 0px;
    }
					}

/* New Header Changes Ends */

@media (max-width: 47.999em){

._7Wr2-.o24CB.fYTBp .Qvkzu {
        padding-left: 1rem;
        padding-right: 1rem;
        padding-top: 0.25rem;
        padding-bottom: 0.25rem;
    }
				}

/* Vis Nav Changes */

.RDx7s {
    margin-top: 1rem;
}

.RDx7s .QXBIO ._-7VNN {
    display: inline-flex;
    align-items: flex-start;
    justify-content: flex-start;
}

.RDx7s .QXBIO ._-7VNN .Sz6rN {
    margin-right: 0.75rem;
    width: 6rem;
    vertical-align: top;
}

.RDx7s .QXBIO ._-7VNN .Sz6rN:first-child {
    margin-left: 1rem;
}

.RDx7s .QXBIO ._-7VNN .Sz6rN:last-child {
    margin-right: 1rem;
}

.RDx7s .QXBIO ._-7VNN .Sz6rN ._33uZL {
    height: 6rem;
    width: 6rem;
    border-radius: 50%;
}

.RDx7s .QXBIO ._-7VNN .Sz6rN .Gqntx {
    font-size: 0.75rem;
    line-height: 1rem;
    font-family: "Open Sans", OpenSans, Helvetica, arial, sans-serif;
    margin-top: 0.75rem;
    text-align: center;
    font-weight: 400;
    --tw-text-opacity: 1;
    color: rgba(32, 32, 32, var(--tw-text-opacity));
}

/* Vis Nav Changes Ends */

._9q67U {
    --tw-text-opacity: 1;
    color: rgba(40, 117, 188, var(--tw-text-opacity));
}

.uugMm {
    position: absolute;
    top: 0.625rem;
    right: 0.625rem;
}

.s8Vb9 .GD-Bd + .GQwSg {
    white-space: nowrap;
}

@media (min-width: 0em) {

    .s8Vb9 .GD-Bd + .GQwSg {
        width: calc(100% - 44px);
    }
}

.Y8V7L {
    position: fixed;
    top: 0px;
    left: 0px;
    z-index: -1px;
    display: block;
    height: 100%;
    width: 100%;
    background-color: rgba(32, 32, 32, var(--tw-bg-opacity));
    --tw-bg-opacity: 0.5;
}

/* Store Hover state css */

._7Wr2-.b3pcM .OZn9u .ZSskb {
    margin-top: 0px;
    border-width: 0px;
    --tw-bg-opacity: 1;
    background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
    --tw-shadow: 0 3px 8px 0 rgba(32, 32, 32, 0.25);
    --tw-shadow-colored: 0 3px 8px 0 var(--tw-shadow-color);
    box-shadow: 0 0 rgba(0,0,0,0), 0 0 rgba(0,0,0,0), var(--tw-shadow);
}

._7Wr2-.b3pcM .OZn9u .ZSskb::after {
    right: 38px;
    content: var(--tw-content);
    border-width: 8px;
}

@media (min-width: 0em) {

    ._7Wr2-.b3pcM .OZn9u .ZSskb {
        width: 312px;
    }
}

._7Wr2-.b3pcM .OZn9u .vZ-6h {
    display: flex;
    align-items: flex-start;
    text-align: left;
    --tw-text-opacity: 1;
    color: rgba(32, 32, 32, var(--tw-text-opacity));
}

._7Wr2-.b3pcM .OZn9u .vZ-6h .VYODU {
    font-size: 0.875rem;
    line-height: 1.25rem;
    font-family: "Open Sans", OpenSans, Helvetica, arial, sans-serif;
    font-weight: 600;
}

._7Wr2-.b3pcM .OZn9u .vZ-6h .VYODU .lDLl0 {
    display: block;
    width: 7rem;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    text-align: left;
}

._7Wr2-.b3pcM .OZn9u .vZ-6h .VYODU .hlFth {
    font-family: "Open Sans", OpenSans, Helvetica, arial, sans-serif;
    font-weight: 400;
    font-size: 0.75rem;
    line-height: 1;
}

._7Wr2-.b3pcM .OZn9u .pMKpf {
			box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.2);
		}

._7Wr2-.b3pcM .OZn9u .pMKpf::before {
				transform: none!important;
			}

._7Wr2-.b3pcM .OZn9u .pMKpf::before {
				content: '';
				z-index: 2;
				right: 92px;
				bottom: 100%;
				width: 0;
				height: 0;
				border-left: 7px solid transparent;
				border-right: 7px solid transparent;
				border-bottom: 7px solid rgba(0,0,0,0.09);
				border-top: 7px solid transparent;
				position: absolute;
			}

._7Wr2-.b3pcM .OZn9u .pMKpf::after{
				transform: none!important;
				right: 93px!important;
				border-left: 6px solid transparent!important;
				border-right: 6px solid transparent!important;
				border-bottom: 6px solid #FFF!important;
			}

._7Wr2-.b3pcM .OZn9u .pMKpf::after{
				content: '';
				z-index: 3;
				bottom: 100%;
				width: 0;
				height: 0;
				border-top: 6px solid transparent;
				position: absolute;
			}

._7Wr2-.b3pcM .OZn9u .hjZco {
    display: flex;
    align-items: center;
    text-align: left;
}

._7Wr2-.b3pcM .OZn9u .Aab24 {
    padding-top: 0.25rem;
    padding-bottom: 0px;
    padding-left: 0.5rem;
    padding-right: 0px;
}

._7Wr2-.b3pcM .OZn9u .Aab24 ._4JfR- {
    display: none;
}

._7Wr2-.b3pcM .OZn9u ._7HAiN {
    position: absolute;
    width: 5rem;
    cursor: pointer;
}

@media (min-width: 0em) {

    ._7Wr2-.b3pcM .OZn9u ._7HAiN {
        top: -50px;
        right: 18px;
        height: 50px;
    }
}

._9K8xI {
  background: rgba(0,0,0,0);
}

._9K8xI button {
    display: block;
  }

._9K8xI .Zh-RJ {
    top: 87%!important;
}

._9K8xI .Zh-RJ {
    margin-top: 0.5rem;
    width: 312px;
    border-radius: 0px;
    border-width: 0px;
    --tw-bg-opacity: 1;
    background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
    --tw-shadow: 0 3px 8px 0 rgba(32, 32, 32, 0.25);
    --tw-shadow-colored: 0 3px 8px 0 var(--tw-shadow-color);
    box-shadow: 0 0 rgba(0,0,0,0), 0 0 rgba(0,0,0,0), var(--tw-shadow);
    box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.2);
}

._9K8xI .Zh-RJ::before {
      transform: none!important;
    }

._9K8xI .Zh-RJ::before {
      content: '';
      position: absolute;
      z-index: 2;
      right: 72px;
      bottom: 100%;
      width: 0;
      height: 0;
      border-left: 7px solid transparent;
      border-top: 7px solid transparent;
      border-right: 7px solid transparent;
      border-bottom: 7px solid rgba(0,0,0,0.09);
    }

._9K8xI .Zh-RJ::after{
      transform: none!important;
      right: 73px!important;
      border-left: 6px solid transparent!important;
      border-top: 6px solid transparent!important;
      border-right: 6px solid transparent!important;
      border-bottom: 6px solid #FFF!important;
    }

._9K8xI .Zh-RJ::after{
      content: '';
      z-index: 3;
      bottom: 100%;
      position: absolute;
      width: 0;
      height: 0;
    }

._9K8xI .u7z6L ul li:first-child {
    border-top-width: 0px;
}

@media (min-width: 0em) {

    ._9K8xI .u7z6L ._7HAiN {
        right: 7px;
        width: 190px;
    }
}

._9K8xI ._8gsRn {
    margin-left: 1.25rem;
    margin-right: 1.25rem;
    margin-bottom: 0px;
}

@media (min-width: 0em) {

    ._9K8xI ._8gsRn {
        margin-top: 1.5rem;
        width: 87%;
    }
}

._9K8xI .BbT7G {
    margin-left: 1.25rem;
    margin-right: 1.25rem;
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
}

._9K8xI ._7HAiN {
    position: absolute;
    right: 2.25rem;
    cursor: pointer;
}

@media (min-width: 0em) {

    ._9K8xI ._7HAiN {
        top: -5px;
        height: 50px;
        width: 130px;
    }
}

._9K8xI.KDPgZ {
    padding: 0px;
}

._9K8xI.KDPgZ .Zh-RJ::after {
    content: var(--tw-content) !important;
    right: 72px !important;
}

._9K8xI.KDPgZ .Zh-RJ {
      top: 87%!important;
    }

._9K8xI.KDPgZ .Zh-RJ {
      box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.2);
    }

._9K8xI.KDPgZ .Zh-RJ::before {
        transform: none!important;
      }

._9K8xI.KDPgZ .Zh-RJ::before {
        content: '';
        z-index: 2;
        right: 72px;
        bottom: 100%;
        width: 0;
        height: 0;
        border-left: 7px solid transparent;
        border-right: 7px solid transparent;
        border-bottom: 7px solid rgba(0,0,0,0.09);
        position: absolute;
      }

._9K8xI.KDPgZ .Zh-RJ::after{
        transform: none!important;
        right: 73px!important;
        border-left: 6px solid transparent!important;
        border-right: 6px solid transparent!important;
        border-bottom: 6px solid #FFF!important;
      }

._9K8xI.KDPgZ .Zh-RJ::after{
        content: '';
        z-index: 3;
        bottom: 100%;
        width: 0;
        height: 0;
        position: absolute;
      }
