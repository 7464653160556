@font-face {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    src: local('Open Sans'), local('OpenSans'), url("https://fonts.gstatic.com/s/opensans/v17/mem8YaGs126MiZpBA-UFVZ0bf8pkAg.woff2") format('woff2'), url(https://fonts.gstatic.com/s/opensans/v13/cJZKeOuBrn4kERxqtaUH3bO3LdcAZYWl9Si6vvxL-qU.woff) format('woff');
    font-display: swap;
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 600;
    src: local('Open Sans Semibold'), local('OpenSans-Semibold'), url("https://fonts.gstatic.com/s/opensans/v17/mem5YaGs126MiZpBA-UNirkOUuhpKKSTjw.woff2") format('woff2'), url(https://fonts.gstatic.com/s/opensans/v13/MTP_ySUJH_bn48VBG8sNSnhCUOGz7vYGh680lGh-uXM.woff) format('woff');
    font-display: swap;
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 700;
    src: local('Open Sans Bold'), local('OpenSans-Bold'), url("https://fonts.gstatic.com/s/opensans/v17/mem5YaGs126MiZpBA-UN7rgOUuhpKKSTjw.woff2") format('woff2'), url(https://fonts.gstatic.com/s/opensans/v13/k3k702ZOKiLJc3WVjuplzKRDOzjiPcYnFooOUGCOsRk.woff) format('woff');
    font-display: swap;
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
    font-family: 'Open Sans';
    font-style: italic;
    font-weight: 400;
    src: local('Open Sans Italic'), local('OpenSans-Italic'), url("https://fonts.gstatic.com/s/opensans/v17/mem6YaGs126MiZpBA-UFUK0Zdc1GAK6b.woff2") format('woff2'), url(https://fonts.gstatic.com/s/opensans/v13/xjAJXh38I15wypJXxuGMBrrIa-7acMAeDBVuclsi6Gc.woff) format('woff');
    font-display: swap;
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
    font-family: "Open Sans";
    font-style: italic;
    font-weight: 600;
    font-display: swap;
    src: local("Open Sans Semibold Italic"), local("OpenSans-SemiBoldItalic"),
        url("https://fonts.gstatic.com/s/opensans/v17/memnYaGs126MiZpBA-UFUKXGUdhrIqOxjaPX.woff2") format("woff2");
        unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
	font-family: "Open Sans";
	font-style: italic;
	font-weight: 700;
	font-display: swap;
	src: local("Open Sans Bold Italic"), local("OpenSans-BoldItalic"),
		 url("https://fonts.gstatic.com/s/opensans/v17/memnYaGs126MiZpBA-UFUKWiUNhrIqOxjaPX.woff2") format("woff2");
	unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

:root {
  /*
    * These below colours would be deprecated after design implementation
    * As these colours are used in current core, site and other repositories
  */

  /**
    * Below colours are from new style guidelines
  */
  /* Primary colors RED Orange */ /* Red */ /* Red */ /* Dark Red*/

  /* Black scale */ /* Black */ /* Thick Black */ /* light black */

  /* White scale */ /* white */ /* Light White */  /* One kind of white */

  /* Gray scale */ /* Gray */ /* Version of Light Gray */ /* Light Gray */ /* Davy Gray */ /* Solid Gray */ /* Solid Light Gray */ /* Dark Gray */ /* Dark Solid Gray */

  /* Inherited from Design Token to remove */

  /* Other Colors */ /* Turquoise */
  
  /* Extended */

  /* Messaging colors */ /* Anchor Blue */

  /* Message Alert colors */

  
  /* a.n.a Rebrand Colors */
  /* a.n.a Rebrand Colors Ends */

}

/* media queries for different width */

/* Medium plus screens */

/* Large plus screens */

/***
    Added only to Make the Header and Footer as Per the classice
    Have to remove once the Yoda Header Footer developed
***/

/***
    Adding  below for Header ReDesign
***/

.Vz-o6 {

    /* Desktop SVG Arrow Ends */

    /* Mega Menu Specific CSS */
}

.Vz-o6.k-rlD .r-AlY .mIfRo {
                transition: height 0.3s ease-out, opacity 0.3s ease, width 0.3s ease-out;
            }

.Vz-o6.k-rlD .r-AlY .Fq8bM {
                transition: height 0.3s ease-out, opacity 0.3s ease, width 0.3s ease-out,
                    left 0.3s ease-out;
            }

.Vz-o6.k-rlD .r-AlY .aZP1J {
                animation: xARQh 0.2s linear;
                transition: height 0.3s ease-out, opacity 0.3s ease, width 0.3s ease-out,
                    left 0.3s ease-out;
            }

.Vz-o6.k-rlD .r-AlY .jobsx {
                column-width: auto;
                -moz-column-gap: 0;
                -moz-column-width: auto;
                animation: ab0cB 0.5s linear;
            }

.Vz-o6.k-rlD .r-AlY .jobsx .qW7rt {
    max-width: 178px;
}

.Vz-o6.k-rlD .r-AlY .jobsx .dAgUF.nWAi8 {
    width: 210px;
    padding-right: 1.75rem;
}

.Vz-o6.k-rlD .r-AlY .jobsx .dAgUF._2nm2G {
    width: 387px;
}

/* Will remove/change based on the rendering functionality */

.Vz-o6 .aZP1J .jobsx .dAgUF {
                -moz-column-break-inside: avoid;
                     break-inside: avoid; /* IE 10+ */
                page-break-inside: avoid; /* Firefox */
                -webkit-column-break-inside: avoid; /* Chrome, Safari, Opera */
            }

.Vz-o6 .aZP1J .jobsx ._-2-wL .XnyZZ .EvPyV {
    text-underline-offset: 4px;
}

.Vz-o6 .aZP1J .jobsx .U-BcB .EvPyV.jAAEN {
    cursor: default;
    text-decoration-line: none;
}

.Vz-o6 .aZP1J .jobsx .U-BcB .EvPyV.jAAEN:hover {
    text-decoration-line: none;
}

/* Bot CSS */

._9Y-bF {
    -moz-column-count: 3;
         column-count: 3;
    -moz-column-gap: 16px;
         column-gap: 16px;
    height: 100%;
    -moz-column-fill: auto;
         column-fill: auto;
    -moz-column-width: 216px;
         column-width: 216px;
}

._9Y-bF.ft1ig {
        display: none;
    }

._9Y-bF .-jzqm {
        color: #006C7C;
    }

._9Y-bF .jCFku {
        color: var(---color-gray-3);
    }

._9Y-bF ._1eh0w {
        width: 100%;
        -moz-column-break-inside: avoid;
             break-inside: avoid;
        page-break-inside: avoid;
    }

/* Bot CSS Ends */

@keyframes V17Di {
    from {
        transform: translate(16px, 20px);
    }
    to {
        transform: translate(0px, 0px);
    }
}

@keyframes xARQh {
    0% {
        height: 100%;
        opacity: 0;
        visibility: hidden;
    }
    20% {
        opacity: 0.25;
    }
    40% {
        opacity: 0.5;
    }
    60% {
        opacity: 0.75;
    }
    100% {
        opacity: 1;
        visibility: visible;
    }
}

@keyframes ab0cB {
    0% {
        opacity: 0;
        visibility: hidden;
    }
    20% {
        opacity: 0.25;
    }
    40% {
        opacity: 0.5;
    }
    60% {
        opacity: 0.75;
    }
    100% {
        opacity: 1;
        visibility: visible;
    }
}
